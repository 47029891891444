import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { AltAboutJk } from "@components/AboutJk"
import { Button, PillButton } from "@components/Button"
import { Carousel } from "@components/Carousel"
import { ClientList } from "@components/ClientList"
import { CursorMarker } from "@components/CursorMarker"
import { Layout } from "@components/Layout"
import { Arrow } from "@components/UI"
import Illustration from "@svg/social-good/illustration.svg"
import PovIcon from "@svg/social-good/pov-icon.svg"
/*global gtag*/
import { SalesForm } from "@components/Form"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import ReportingIcon from "@svg/social-good/reporting-icon.svg"
import StoryIcon from "@svg/social-good/story-icon.svg"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import React from "react"

const SocialGoodPage = ({ data }) => {
  const { aboutJkImage, heroImage, csMarcosImage, csChildrenImage, csTiffanyImage } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const contactSubmission = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }

    gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "social_storytelling" })
  }

  return (
    <Layout>
      <Seo title="Social Good" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <h1
                    className="mt-12 text-clamp-30-35"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "Doing good matters. <span>So does how you talk about it.</span>",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="font-bold text-white text-clamp-16-18">
                  A recent poll by PwC found 86% of people prefer to support or work for companies that care about the
                  same issues they do.
                  <sup>
                    <a
                      href="https://www.pwc.com/us/en/services/consulting/library/consumer-intelligence-series/consumer-and-employee-esg-expectations.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      1
                    </a>
                  </sup>
                </p>
                <p className="text-white text-clamp-16-18">
                  Communicating where your brand stands is now a business imperative. We can help you create and share a
                  powerful social good story that connects with your people, partners, and customers.
                </p>
                <div>
                  <Button className="mt-8 text-purple-light" link="#social-good-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
              <div className="flex sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-6">
                  <div className="relative flex items-center col-span-7 sm:col-span-6 lg:col-span-5 lg:col-start-2">
                    <div className="pb-6 sm:pl-5 lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative" data-aos="fade-left">
                        <GatsbyImage objectFit="contain" image={getImage(heroImage)} alt="Social Good" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="mt-8">
          <section className="pb-clamp-16-18">
            <div className="container">
              <div data-aos="fade">
                <PillButton staticButton={true} className="bg-purple-lighter">
                  Our services
                </PillButton>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col w-full grid-cols-3 gap-8 lg:pl-6 sm:w-10/12 lg:gap-4 lg:w-full lg:grid mt-clamp-9-12">
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up">
                    <div>
                      <PovIcon />
                    </div>
                    <div>
                      <h2 className="font-bold text-20">POV development</h2>
                      <p className="mt-4 text-16">
                        Craft a meaningful social good story rooted in your mission, vision, and values—and establish a
                        platform for cohesive, consistent messaging at every touchpoint.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={100}>
                    <div>
                      <ReportingIcon />
                    </div>
                    <div>
                      <h2 className="font-bold text-20">Reporting</h2>
                      <p className="mt-4 text-16">
                        Tell your social good story in a way that engages, is aligned with reporting best practices and
                        frameworks, and conveys your social good impact.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={200}>
                    <div>
                      <StoryIcon />
                    </div>
                    <div>
                      <h2 className="font-bold text-20">Communications and engagement </h2>
                      <p className="mt-4 text-16">
                        Authentically communicate your social good strategy to audiences beyond the boardroom: the
                        people inside your company and your customers on the outside.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-10 lg:pb-16 md:pt-clamp-14-30 lg:pt-28 bg-teal-medium">
            <div className="container">
              <div className="grid-cols-12 gap-4 lg:grid">
                <div className="flex items-center col-span-5 col-start-3" data-aos="fade-right">
                  <div>
                    <h3 className="leading-normal text-clamp-35-45">
                      Let’s talk about telling your very best social good story!
                    </h3>
                    <div>
                      <Button className="mt-8 text-gray-darkest button-down" down={true} link="#social-good-form">
                        Get in touch
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="relative flex justify-center col-span-5 mt-10 lg:mt-0 lg:block">
                  <div
                    className="relative flex justify-center w-full sm:w-10/12 lg:block lg:w-160 z-1"
                    data-aos="fade-left"
                  >
                    <Illustration />
                  </div>
                  <div className="absolute inset-x-0 bottom-0 lg:hidden">
                    <div className="aspect-w-667 aspect-h-93 extend before:bg-white-dark after:bg-white-dark"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-clamp-10-30 pb-clamp-16-30">
            <div className="container">
              <div>
                <div data-aos="fade">
                  <PillButton staticButton={true} className="bg-purple-lighter">
                    Our Clients
                  </PillButton>
                </div>
                <h2
                  className="mt-10 text-clamp-35-45"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-gray-darkest": "Goodness we’ve shared.",
                    }),
                  }}
                />
                <ClientList
                  className="gap-x-6 lg:gap-x-20 mt-clamp-9-8"
                  clients={[
                    "amgen",
                    "caterpillar",
                    "children",
                    "j-and-j",
                    "kenvue",
                    "marcos",
                    "merck",
                    "netflix",
                    "stryker",
                    "tiffany",
                  ]}
                />
              </div>
            </div>
          </section>

          <section className="bg-gray pt-clamp-12-14 pb-clamp-15-25">
            <div className="container">
              <div>
                <PillButton className="bg-teal-medium" staticButton={true}>
                  CASE STUDY SNAPSHOTS
                </PillButton>
                <div className="mt-12">
                  <h2
                    className="leading-none text-clamp-35-45"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "See our storytelling in action.",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="hidden grid-cols-3 gap-4 lg:grid mt-clamp-11-20">
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csMarcosImage)} alt="MARCO’S PIZZA" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">MARCO’S PIZZA</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Satisfying Marco’s hunger for an irresistible CSR brand.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      Marco’s Pizza had all the ingredients for an inspiring social good story—but needed to blend it
                      all together and roll it out. JK created a CSR identity that telegraphs their beliefs, and a
                      toolkit that empowers everyone to share their CSR story consistently.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csChildrenImage)} alt="THE CHILDREN’S PLACE" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">THE CHILDREN’S PLACE</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Reimagining a specialty retailer’s ESG report.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      The Children’s Place wanted to transform their ESG report into a dynamic, engaging, visually
                      driven experience. JK found creative ways to weave together facts and feelings—making complex
                      information digestible and accessible for all their audiences.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csTiffanyImage)} alt="TIFFANY & CO." />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">TIFFANY & CO.</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Bringing the power, promise, and proof of DEI at Tiffany to life.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      This iconic company has a history of DEI leadership in the luxury space—but they lacked a cohesive
                      DEI story. We created a message map and leader toolkit that clearly conveys the impact of their
                      DEI commitments on their talent, culture, and brand.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container lg:hidden mt-clamp-11-20">
              <div className="relative flex">
                <CursorMarker>
                  <CursorMarker.Gallery />
                </CursorMarker>
                <div className="w-full sm:w-5/6 lg:w-1/2">
                  <Carousel>
                    <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csMarcosImage)} alt="MARCO’S PIZZA" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">MARCO’S PIZZA</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Satisfying Marco’s hunger for an irresistible CSR brand.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              Marco’s Pizza had all the ingredients for an inspiring social good story—but needed to
                              blend it all together and roll it out. JK created a CSR identity that telegraphs their
                              beliefs, and a toolkit that empowers everyone to share their CSR story consistently.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csChildrenImage)} alt="THE CHILDREN’S PLACE" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">THE CHILDREN’S PLACE</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Reimagining a specialty retailer’s ESG report.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              The Children’s Place wanted to transform their ESG report into a dynamic, engaging,
                              visually driven experience. JK found creative ways to weave together facts and
                              feelings—making complex information digestible and accessible for all their audiences.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csTiffanyImage)} alt="TIFFANY & CO." />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">TIFFANY & CO.</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Bringing the power, promise, and proof of DEI at Tiffany to life.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              This iconic company has a history of DEI leadership in the luxury space—but they lacked a
                              cohesive DEI story. We created a message map and leader toolkit that clearly conveys the
                              impact of their DEI commitments on their talent, culture, and brand.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62 pb-clamp-37-60">
            <AltAboutJk>
              <AltAboutJk.Content>
                JK blends empathy, insight, and a clear understanding of your goals to deliver communications platforms
                and programs that build the bonds that grow your business.
              </AltAboutJk.Content>
              <AltAboutJk.ImageV2 image={aboutJkImage} shape="one" />
            </AltAboutJk>
          </section>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <div id="social-good-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-purple-medium pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-8">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Let’s build a  stronger ",
                          break: "",
                          "text-gray-darkest": "social good story, together.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Social Good"
                  recipient="katiek@jkdesign.com, markm@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default SocialGoodPage

export const SocialGoodPageQuery = graphql`
  query socialGoodPageQuery {
    heroImage: file(relativePath: { eq: "social-story/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    goingBeyondImage: file(relativePath: { eq: "social-story/going-beyond.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csMarcosImage: file(relativePath: { eq: "social-story/marcos.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csChildrenImage: file(relativePath: { eq: "social-story/children.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csTiffanyImage: file(relativePath: { eq: "social-story/tiffany.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "social-story/about.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
